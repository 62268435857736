
import { defineComponent, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import BANCard from "@/shared/components/cards/BANCard.vue";
import AccountCard from "@/shared/components/cards/AccountCard.vue";
import GlobalSearchInput from "@/shared/components/forms/GlobalSearchInput.vue";
import GhostListHeader from "@/shared/components/layouts/GhostListHeader.vue";
import PageHeaderTitle from "@/shared/components/typography/PageHeaderTitle.vue";
import routeNames from "@/web/router/routeNames";
import { accountStatisticsGql } from "@/api/accounts/accountStats";
import { useGetAccountList } from "@/shared/composables/api/useGetAccountList";
import { useQuery } from "@vue/apollo-composable";
import {
  AccountStatistics,
  AccountStatistics_accountStatistics_AccountStatistics,
} from "@/api/accounts/__generated__/AccountStatistics";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import List from "@/shared/components/lists/List.vue";
import { convertAmountToAda } from "@/shared/utils/numbers";

export default defineComponent({
  setup() {
    const t = useI18n().t;

    const accountStatistics = useQuery<AccountStatistics>(
      accountStatisticsGql,
      {}
    );
    const parsedGqlAccountStatistics = computed(() =>
      parseGqlResponse<AccountStatistics_accountStatistics_AccountStatistics>(
        "AccountStatistics",
        accountStatistics?.result?.value
      )
    );

    const accountStats = computed(() => {
      return [
        {
          id: 1,
          cardTitle: t("Total Accounts"),
          cardAmount: parsedGqlAccountStatistics?.value?.data?.accountsCount,
          unitType: "blocks",
        },
        {
          id: 2,
          cardTitle: t("Total Amount"),
          cardAmount: convertAmountToAda(
            parsedGqlAccountStatistics?.value?.data?.amount
          ),
        },
        {
          id: 3,
          cardTitle: t("Total Rewards"),
          cardAmount: convertAmountToAda(
            parsedGqlAccountStatistics?.value?.data?.rewardsAmount
          ),
        },
      ];
    });

    const {
      mappedDataForDisplay: accountListForDisplay,
      showMore,
      showMoreIsLoading,
      isLoading: isListLoading,
    } = useGetAccountList();

    const gotoAccountDetails = (hash) => ({
      name: routeNames.accountDetails,
      params: { hash },
    });

    return {
      t,
      gotoAccountDetails,
      accountStats,
      accountStatistics,
      accountListForDisplay,
      searchRef: ref(""),
      showMore,
      showMoreIsLoading,
      isListLoading,
    };
  },
  components: {
    BANCard,
    AccountCard,
    GlobalSearchInput,
    GhostListHeader,
    PageHeaderTitle,
    List,
  },
});
