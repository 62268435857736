import { computed, ref, toRaw, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import {
  Accounts,
  Accounts_accounts_AccountResults,
  Accounts_accounts_AccountResults_results,
  AccountsVariables,
} from "@/api/accounts/__generated__/Accounts";
import { accountListGql } from "@/api/accounts/accountList";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { convertAmountToAda } from "@/shared/utils/numbers";
import { useFetchMoreListMerge } from "@/shared/composables/useMergeList";

const accountListDisplayMapper = (
  itemList: (Accounts_accounts_AccountResults_results | null)[] | null
) => {
  return (itemList ?? []).map((item) => {
    return {
      id: item?.id,
      transactionId: item?.hash,
      amount1: convertAmountToAda(item?.amount),
      amount2: convertAmountToAda(item?.rewardsAmount),
      percentage1: item?.amountPercentage * 100,
      percentage2: item?.rewardsAmountPercentage * 100,
      // Optimize hide and show of Arrow Up and Down
      isArrowUp: false,
    };
  });
};

export const useGetAccountList = () => {
  const mergedAccountList = ref<ReturnType<typeof accountListDisplayMapper>>(
    []
  );
  const showMoreButtonTriggered = ref(false);
  const { fetchMoreListMerge } = useFetchMoreListMerge();
  const defaultPageSize = 21;
  const accountList = useQuery<Accounts, AccountsVariables>(
    accountListGql,
    () => ({
      filters: {
        pagination: {
          offset: null,
          pageSize: defaultPageSize,
        },
      },
    })
  );

  const parsedGqlAccountList = computed(() =>
    parseGqlResponse<Accounts_accounts_AccountResults>(
      "AccountResults",
      accountList?.result?.value
    )
  );

  watch(
    parsedGqlAccountList,
    (newParsedGqlAccList) => {
      if (
        newParsedGqlAccList.data?.results &&
        newParsedGqlAccList.data?.results.length > 1
      ) {
        const mappedDisplay = accountListDisplayMapper(
          newParsedGqlAccList.data.results
        );

        const mergedList = fetchMoreListMerge<
          ArrayElement<ReturnType<typeof accountListDisplayMapper>>
        >(
          toRaw(mergedAccountList.value),
          mappedDisplay ?? [],
          "id",
          showMoreButtonTriggered.value
        );

        mergedAccountList.value = mergedList;
        showMoreButtonTriggered.value = false;
      }
    },
    {
      immediate: true,
    }
  );

  const showMore = () => {
    showMoreButtonTriggered.value = true;
    accountList.refetch({
      filters: {
        pagination: {
          offset: mergedAccountList.value.length,
          pageSize: defaultPageSize,
        },
      },
    });
  };

  return {
    mappedDataForDisplay: mergedAccountList,
    showMore,
    showMoreIsLoading: showMoreButtonTriggered,
    isLoading: accountList?.loading,
  };
};
