/**
 * Use this function to merge new list items on a list while preserving the
 * order of the list and appending new items at the end of the list
 *
 * for some reason the default
 */
import isEmpty from "lodash/isEmpty";
import { ref } from "vue";

export function useFetchMoreListMerge() {
  const maxLimit = ref(100);

  return {
    fetchMoreListMerge: <T>(
      previousResults: T[],
      newResults: T[],
      idKeyName = "id",
      inverseOrder = false
    ) => {
      const repeatingIds: (string | undefined)[] = [];
      const prevResultsFiltered = [
        ...(previousResults ?? []).map((item) => {
          const itemNotOnPreviousResult = newResults.find((newResultsItem) => {
            return (
              newResultsItem &&
              item &&
              newResultsItem[idKeyName] === item[idKeyName]
            );
          });

          if (itemNotOnPreviousResult) {
            repeatingIds.push(itemNotOnPreviousResult[idKeyName]);
            return itemNotOnPreviousResult;
          }

          return item;
        }),
      ];
      const newResultsFiltered = [
        ...newResults.filter(
          (item) => !repeatingIds.includes(item && item[idKeyName])
        ),
      ];

      // Put in a variable for easy debugging.
      const returnArray = [
        // Need to reverse the order since latest transactions should be at the top
        ...newResultsFiltered,
        ...prevResultsFiltered,
        // Only limit maximum of 100 items on view
      ].slice(0, maxLimit.value);

      // This means that new results are older than the previous result
      // That's why we need to show it at the end of the list instead
      if (inverseOrder) {
        if (!isEmpty(newResultsFiltered)) {
          maxLimit.value = maxLimit.value + newResultsFiltered.length;
        }
        return [...prevResultsFiltered, ...newResultsFiltered];
      }

      return returnArray;
    },
  };
}
