import gql from "graphql-tag";

export const accountListGql = gql`
  query Accounts($filters: AccountFiltersInput) {
    accounts(filters: $filters) {
      ... on AccountResults {
        results {
          id
          hash
          amount
          amountPercentage
          rewardsAmount
          rewardsAmountPercentage
        }
        pageInfo {
          nextOffset
        }
      }
      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
