import gql from "graphql-tag";

export const accountStatisticsGql = gql`
  query AccountStatistics {
    accountStatistics {
      ... on AccountStatistics {
        accountsCount
        amount
        rewardsAmount
      }
      ... on ResponseErrors {
        errors {
          message
          displayMessage
          code
        }
      }
    }
  }
`;
